.dashboard {
  display: flex;
  height: 100vh;
  background-color: #f0f4f8;
}

.sidebar {
  background-color: #ffffff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  position: relative;
  flex-shrink: 0;
}

.sidebar.open {
  width: 250px;
}

.sidebar.closed {
  width: 80px;
}

.toggle-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #3182ce;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-content {
  padding: 20px;
  padding-top: 50px;
  height: 100%;
  overflow-y: auto;
}

.dashboard-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2c5282;
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out;
}

.sidebar.closed .dashboard-title {
  opacity: 0;
}

.nav-btn {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #4a5568;
  transition: all 0.2s ease-in-out;
}

.nav-btn:hover, .nav-btn.active {
  background-color: #ebf8ff;
  color: #2b6cb0;
}

.nav-btn .icon {
  margin-right: 10px;
  min-width: 24px;
}

.logo-sidebar{
  display: flex;
  max-width: 100%;
}

.sidebar.closed .logo-sidebar{
  opacity: 0;
}
.sidebar.closed .nav-btn {
  justify-content: center;
}

.sidebar.closed .btn-text {
  display: none;
}

.sidebar.closed .nav-btn .icon {
  margin-right: 0;
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
}

.card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.card-header {
  background-color: #ebf8ff;
  padding: 15px 20px;
}

.card-title {
  margin: 0;
  color: #2c5282;
  font-size: 1.25rem;
}

.card-content {
  padding: 20px;
}

.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 600px;
  background-color: #f0f4f8;
  color: #4a5568;
}

.placeholder svg {
  margin-bottom: 10px;
}
.iframe-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
}

.iframe-content {
  width: 100%;
  height: 90%;
}

@media (max-width: 767px) {
  .iframe-container {
    height: auto;
    padding-bottom: 0;
  }

  .iframe-content {
    position: relative;
    height: 70vh; /* Adjust this value as needed for mobile */
  }
}