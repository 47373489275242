/* src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: #ffffff;
  width: 100%;
}

h2 {
  margin-top: 0;
}

p {
  line-height: 1.6;
}
